
import { Component, Vue } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import { apiPackageItemAdd, apiPackageItemDetail, apiPackageItemEdit, apiGoodsLists } from '@/api/goods'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        AreaSelect,
		MaterialSelect
    }
})
export default class AddPackage extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    form = {
        name: '',
		zone_size: '',
		zone_brand: '',
		zone_style: '',
		zone_colors: '',
		images: '',
		package_id: this.$route.query.package_id,
		good_ids:"",
		item_ids:"",
		nums:'',
        sort: '',
		addnum:1,
		additemid:0,
		items: "",
    }
	
	queryObj = {
		package_id: this.$route.query.package_id,
	}
	
	// 商品列表
	goodsList: Array<object> = []
	
	// 选中的商品列表
	itemList: Array<object> = []

    rules = {
        name: [
            {
                required: true,
                message: '请输入套餐名称',
                trigger: ['blur', 'change']
            }
        ],
        category_id: [
            {
                required: true,
                message: '请选择套餐分类',
                trigger: ['blur', 'change']
            }
        ]
    }
    categoryLists = []
    handleSave() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                const api = this.id ? apiPackageItemEdit(this.form) : apiPackageItemAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }
	additem(){
		if( this.form.additemid==0 || this.form.addnum<1 ){
			return;
		}
		this.itemList.push({"item_id":this.form.additemid,"num":this.form.addnum})
		this.form.additemid = 0
		this.form.addnum = 1
		this.form.items = JSON.stringify( this.itemList )
	}
	delItem(n:any){
		if( confirm("确定删除吗？") ){
			this.itemList.splice(n,1)
			this.form.items = JSON.stringify( this.itemList )
		}
	}

    getPackageItemDetail() {
        this.loading = true
        apiPackageItemDetail(this.id).then((res: any) => {
            this.form = res
            this.loading = false
			
			this.itemList = res.items
			this.form.items = JSON.stringify( this.itemList )
        })
    }

    // 初始化商品
    initGoodsLists() {
		const loading = this.$loading({
			lock: true,
			text: '加载中...',
			spinner: 'el-icon-loading',
		});
        apiGoodsLists({page_size:10000}).then(res => {
			loading.close();
            this.goodsList = res.lists
        })
    }

    created() {
		this.initGoodsLists();
        this.id = this.$route.query.id
        this.id && this.getPackageItemDetail()
		this.queryObj.package_id = this.$route.query.package_id
		this.form.package_id = this.$route.query.package_id
    }
}
